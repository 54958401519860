import React from 'react';

const CV = () => {
    const fileID = '1SVEf0iOxH__52cRta9vGEh8NTTrqqXxv';
    const embedUrl = `https://drive.google.com/file/d/${fileID}/preview`;

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#f5f5f7', // Light background for a professional look
            padding: '20px',
            boxSizing: 'border-box'
        }}>
            <iframe
                src={embedUrl}
                title="My CV"
                width="80%" // Reduces the width for better visual balance
                height="90%" // Keeps a comfortable height
                style={{
                    border: '1px solid #ccc', // Adds a subtle border for definition
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Adds a professional shadow
                    borderRadius: '10px' // Rounds the corners for a polished look
                }}
                allow="autoplay"
            >
                This browser does not support PDFs. Please download the PDF to view it: 
                <a href={`https://drive.google.com/uc?export=download&id=${fileID}`}>Download PDF</a>.
            </iframe>
        </div>
    );
};

export default CV;
