import React, { useState } from 'react';
import './Contact.css';
import emailjs from 'emailjs-com';

function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    
        // Pass the form data correctly
        const templateParams = {
            from_name: formData.name,  // Send form name as from_name
            from_email: formData.email, // Send form email as from_email
            subject: formData.subject,  // Send form subject
            message: formData.message,  // Send form message
            to_name: 'Recipient Name',  // Change this to the recipient's name or leave as static
        };

        emailjs.send(
            'service_zttlgy5', // Replace with your Service ID
            'template_txxubke', // Replace with your Template ID
            templateParams,  // Use templateParams here instead of e.target
            'kThCidWyibR3wfoYU' // Replace with your User ID
        )
        .then((result) => {
            alert('Message sent successfully!');
        })
        .catch((error) => {
            console.error('Error:', error);
            alert('Failed to send message.');
        });
    
        // Reset form fields after successful submission
        setFormData({
            name: '',
            email: '',
            subject: '',
            message: ''
        });
    };

    return (
        <div>
            <form className="contact-form" onSubmit={handleSubmit}>
                <label>Name:</label>
                <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                />

                <label>Email:</label>
                <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />

                <label>Subject:</label>
                <input
                    type="text"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    required
                />

                <label>Message:</label>
                <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                ></textarea>

                <button type="submit">Send</button>
            </form>
        </div>
    );
}

export default Contact;
